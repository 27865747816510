import React, { Component } from 'react'
import 'assets/themes/components/Footer.scss'

export default class Footer extends Component {
	render() {
		return (
			<footer className='footer'>
				<div className='footer-wrapper'>
					<div className='footer-contact-info'>
					</div>
					<div className='footer-mobile'>
						<span>director@alenaudit.com.ua</span>
					</div>
					<div className='footer-contact-info'>
						<span>+380 (97) 091 58 22</span>
					</div>
				</div>
			</footer>
		)
	}
}
