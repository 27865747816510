const servicesList = [
	{
		title: 'Бухгалтерський аутсорсинг (можливий повний цикл або ведення окремих зон обліку)',
		selected: false,
		hideFeaturesList: false,
		featuresList: [
			'Комплексне ведення бухгалтерського та податкового обліку',
			'Здійснення функцій головного бухгалтера',
			'Підготовка фінансової та податкової звітності',
			'Ведення кадрового діловодства ',
			'ЗЕД-облік',
			'Відновлення бухгалтерського та податкового обліку',
			'Спеціальна пропозиція для ФОП'
		],
		featuresDescriptionList: [
			`Бухгалтерський аутсорсинг може включати такі послуги:`,
				`Складання первинних документів;`,
				`Підготовка, збирання та обробка первинних документів з метою розрахунку податків, звірок з контрагентами;`,
				`Підготовка та здавання всіх видів звітності;`,
				`Підготовка щомісячних фінансових звітів для керівників та власників компанії у погоджених з ними форматі, з відображенням структури місць виникнення прибутку чи витрат;`,
				`Подання інтересів клієнта у податкових органах;`,
				`Усі види нарахувань (аванс, зарплата, премії, відпускні, лікарняні, відрядження тощо), розрахунок всіх видів зарплатних податків;`,
				`Оформлення кадрових та інших документів, що підтверджують взаєморозрахунки із працівниками;`,
				`здійснення банківських операцій, у тому числі валютних`,
				`Розрахунок будь-яких видів податків; контроль за відсутністю заборгованості перед бюджетом;`,
				`Поновлення та аналіз бухгалтерського, податкового обліку, виявлення відсутніх документів, перевірка наявних на законність та правильність оформлення;`,
				`Супровід зовнішньоекономічної діяльності;`,
				`Консультування клієнта з питань податкової оптимізації.`,
				`Перелік необхідних послуг та порядок співробітництва узгоджується з кожним клієнтом індивідуально, залежно від потреб клієнта.`
		],
	},
	{
		title: 'Аудиторські послуги',
		selected: false,
		hideFeaturesList: false,
		featuresList: [
			'Ініціативний аудит фінансової звітності',
			'Податковий аудит',
			'Експрес-аудит',
			'Інші узгоджені процедури',
			'Податкові суперечки (адміністративне та судове оскарження рішень контролюючих органів)'
		],
		featuresDescriptionList: [
			'Спектр надання аудиторських послуг охоплює такі напрямки:',
				`Аналіз стану бухгалтерського та податкового обліку клієнта за звітний період з видачею адресної рекомендації спеціалістів;`,
				`Підтвердження коректності відображення складних операцій на бухгалтерському обліку;`,
				`Аудит фінансової звітності з ініціативи власників чи управлінського персоналу;`,
				`Надання аудиторського висновку з рекомендаціями щодо усунення помилок;`,
				`Спеціальні висновки та виконання узгоджених процедур;`,
				`Перевірка наявності всіх необхідних кадрових документів, що оформлюються щодо кожного працівника та по компанії в цілому, а також оцінка стану кадрової документації з точки зору відповідності законодавчо встановленим вимогам щодо її ведення та зберігання;`,
				`Перевірка даних податкової звітності та правильності заповнення податкових декларацій, а також аналіз податкових баз та виявлення ризикованих операцій, які можуть призвести до податкових спорів, правильності та своєчасності сплати підприємством усіх необхідних податків та зборів;`,
				`Консультування з питань, пов'язаних із необхідністю проходження аудиторської перевірки відповідно до вимог законодавства;`,
				`Due Diligence /Дью Ділідженс (комплексний звіт про існуючі та можливі ризики для потенційного інвестора/покупця бізнес-активу або компанії);`,
				`Інші узгоджені процедури, призначені для вирішення конкретного завдання.`,
				``
		],
	},
	{
		title: 'Трансферне ціноутворення (ТЦУ) ',
		selected: false,
		hideFeaturesList: false,
		featuresList: [
			'Складання звіту з ТЦУ',
			'Підготовка документації з ТЦУ',
			'Консультування з питань ТЦУ',
		],
		featuresDescriptionList: [
			`Трансферне ціноутворення може включати наступні напрямки:`,
			`Оцінка наявності контрольованих операцій;`,
			`Підготовка пакета документації з обґрунтуванням вибору методу підтвердження ціни та відповідності умов контрольованих операцій принципу «витягнутої руки»;`,
			`Актуалізація пакету документацій на момент отримання запиту від контролюючих органів, супроводження перевірки контролюючих органів щодо трансфертного ціноутворення.`,
			`Перелік необхідних послуг та порядок співробітництва узгоджується з кожним клієнтом індивідуально, залежно від потреб клієнта.`
		],
	},
	{
		title: 'Податкове та бухгалтерське консультування',
		selected: false,
		hideFeaturesList: false,
		featuresList: [
			``
		],
		featuresDescriptionList: [
			`Ця послуга може включати такі послуги:`,
				`Консультації щодо постановки податкового обліку;`,
				`Консультування з питань бухгалтерського обліку;`,
				`Консультування щодо застосування нових правових актів у галузі бухгалтерського обліку;`,
				`Консультації, пов'язані із податковою відповідальністю`,
				`Консультації з питань зовнішньоекономічної діяльності (ЗЕД);`,
				`Консультації та супровід з фінансового моніторингу;`,
				`Оптимізація оподаткування;`,
				`Супровід податкових перевірок.`,
				`Надання бухгалтерських і податкових консультацій може здійснюватися постійно, або мати разовий характер.`,
				``
		],
	},
	{
		title: 'Юридичне супроводження',
		selected: false,
		hideFeaturesList: false,
		featuresList: [
			``
		],
		featuresDescriptionList: [
			`Ця послуга може включати такі послуги:`,
				`Усні та письмові юридичні консультації;`,
				`Упорядкування правових документів;`,
				`Укладання договорів, ЗЕД-контрактів, додаткових угод до них;`,
				`Розробка чи оптимізація договірних схем;`,
				`Представництво інтересів клієнта у контролюючих органах;`,
				`Оскарження дій (бездіяльності) посадових осіб правоохоронних, контролюючих органів у межах адміністративної та судової процедури оскарження;`,
				`Послуги з реєстрації та/або ліквідації юридичних осіб та фізичних осіб – підприємців.`,
				`Вартість необхідних послуг та порядок співробітництва узгоджується з кожним клієнтом індивідуально, залежно від потреб клієнта.`
		],
	},,
	{
		title: 'Комплексне керівництво проектами',
		selected: false,
		hideFeaturesList: false,
		featuresList: [
			``
		],
		featuresDescriptionList: [
			`Ця послуга може включати такі послуги:`,
				`Створення бізнес-планів під існуючий або новий напрямок;`,
				`Формування стратегії розвитку компанії: комерційна, маркетингова, операційна, IT;`,
				`Збільшення обсягу продажу (товари/послуги), підвищення якості роботи з існуючими та новими клієнтами;`,
				`Системний аналіз поточної проблематики ринку (товари/послуги);`,
				`Участь у створенні нових продуктів;`,
				`Комунікація із співробітниками компанії з питань покращення поточних бізнес-процесів;`,
				`Аналіз активностей та пропозицій конкурентів (продукти, ціни, терміни, умови);`,
				`Вивчення та аналіз популярних та потенційно затребуваних напрямів, як додаткової гілки існуючого бізнесу;`,
				`Формування ціни товару/послуги на підставі моніторингу ринку, створення індивідуальних пропозицій з урахуванням специфіки та запиту клієнта;`,
				`Підбір та обґрунтування набору послуг (сервісів) Компанії; `,
				`Розробка фінансової моделі Компанії;`,
				`Організація роботи Компанії з нуля під ключ;`,
				`Розробка організаційної структури Компанії;`,
				`Розробка мотивації працівників;`,
				`Підбір, навчання та організація роботи співробітників;`,
				`Розробка, впровадження та контроль виконання регламентів роботи співробітників Компанії;`,
				`Впровадження ІТ продуктів (сайт Компанії, CRM-система);`,
				`Контроль операційної діяльності Компанії;`,
				``,
		],
	},
]

export default servicesList
