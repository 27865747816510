import React, { Component } from 'react'
import { Header } from 'screens'
import Footer from '../components/Footer'
import 'assets/themes/components/Team.scss'
import { TeamAbout } from 'screens'
import data from './data'
import { scroll } from './ScrollTotop'

export default class Team extends Component {
	componentDidMount() {
		scroll()
	}

	render() {
		return (
			<>
				<Header />
				<main className='main-team'>
					<div className='container-main'>
						{data.map((el) => (
							<TeamAbout key={el.id} post={el.post} name={el.name} id={el.id} about={el.about} img={el.img} />
						))}
					</div>
				</main>
				<Footer />
			</>
		)
	}
}
