import React, { Component } from 'react'
import { Header } from 'screens'
import Footer from '../components/Footer'
import 'assets/themes/components/Contact.scss'
import { scroll } from './ScrollTotop'

export default class Contact extends Component {
	componentDidMount() {
		scroll()
	}

	createMarkup() {
		return {
			__html:
				'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d661.4386621937788!2d35.0536645292334!3d48.46123689870317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dbe2db9c240ea7%3A0xa8bb08c4b5bf3bc!2z0L_RgNC-0YHQv9C10LrRgiDQlNC80LjRgtGA0LjRjyDQr9Cy0L7RgNC90LjRhtC60L7Qs9C-LCAzNCwg0JTQvdC40L_RgNC-LCDQlNC90LXQv9GA0L7Qv9C10YLRgNC-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwgNDkwMDA!5e0!3m2!1sru!2sua!4v1580458717841!5m2!1sru!2sua"  className="iframe" width="450" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>',
		}
	}

	render() {
		return (
			<>
				<Header />
				<main className='main-contact'>
					<div className='main-container'>
						<div className='container-google' dangerouslySetInnerHTML={this.createMarkup()} />
						<div className='wrap-contact'>
							<div className='wrap-text_h1 margin22'>Телефони:</div>
							<div className='wrap-text_p margin24'>+380 (97) 091 58 22</div>
							<div className='wrap-text_h1 margin27'>Email:</div>
							<div className='wrap-text_p margin31'>director@alenaudit.com.ua</div>
							<div className='wrap-text_h1 margin34'>Адреса:</div>
							<div className='wrap-text_p'>49000, Україна, м.Дніпро, пр.Д.Яворницького, буд.34, кв.5</div>
						</div>
					</div>
				</main>
				<Footer />
			</>
		)
	}
}
