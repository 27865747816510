import React, { Component } from 'react'
import 'assets/themes/components/ServicesItem.scss'

interface IProps {
	service: IService
	close: (selectedIndex: number) => void
	index: number
	border: boolean
}

interface IService {
	title: string
	selected: boolean
	hideFeaturesList: boolean // detect if any of items open
	featuresList: string[]
	featuresDescriptionList: string[]
}

export default class ServicesItem extends Component<IProps> {
	handleClose = () => {
		this.props.close(this.props.index)
	}

	render() {
		const { service, border } = this.props
		const { selected, title, hideFeaturesList, featuresList, featuresDescriptionList } = service
		return (
			<>
				<div className={'anhor'} id={title}></div>
				<div className={'service-wrapper'}>
					<a
						className={!selected ? 'image-plus' : 'image-plus image-plus-active'}
						href={`#${title}`}
						onClick={this.handleClose}
					></a>
					<div className='feature-info'>
						<div className='feature-title-span'>
							<span className='feature-title'>{title}</span>
						</div>
						<ul className={`features-list ${!hideFeaturesList ? '' : 'features-list-hidden'} ${border ? 'last' : ''}`}>
							{featuresList.map((feature, index) => (
								<li key={index}>{feature}</li>
							))}
						</ul>
						<ul
							className={
								selected ? 'features-description-list features-description-list-visible' : 'features-description-list '
							}
						>
							{featuresDescriptionList.map((feature, index) => (
								<li key={index}>{feature}</li>
							))}
						</ul>
					</div>
				</div>
			</>
		)
	}
}
