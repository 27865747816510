import React from 'react'
import { Main, Client, Contact, Review, Service, Team } from 'screens'
import { BrowserRouter as Router, Route } from 'react-router-dom'

export default () => (
	<Router>
		<Route path='/' exact component={Main} />
		<Route path='/clients' exact component={Client} />
		<Route path='/contact' exact component={Contact} />
		<Route path='/review' exact component={Review} />
		<Route path='/service' exact component={Service} />
		<Route path='/team' exact component={Team} />
	</Router>
)
