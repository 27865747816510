const data = [
    {
        post : 'Директор та засновник',
				name : 'Гузь Ганна Олександрівна',
				img : '../../image/anna.png',
        id : 1,
        about: `Вища фінансово-економічна освіта, сертифікат аудитора №006379, досвід роботи у сфері оподаткування та бухгалтерського обліку – понад 20 років, зокрема робота в податкових органах, у службі внутрішнього аудиту великого комерційного банку. З моменту заснування  власної аудиторської фірми у 2007 році займалася організацією та супроводом бухгалтерського, податкового та управлінського обліку в компаніях різних галузей, у т.ч. на промислових заводах. Ключова спеціалізація зараз:
				<br/>- податкове планування та консультації з питань бухгалтерського та податкового обліку,
				<br/>- податковий та правовий супровід зовнішньо-економічної діяльності та ІТ-проектів,
				<br/>- супровід податкових перевірок та судове оскарження їх результатів,
				<br/>- підготовка документації з трансферного ціноутворення (ТЦО),
				<br/>- звітність та оподаткування контрольованих іноземних компаній (КВК),
				<br/>- Проведення Due Diligence (оцінка можливих ризиків перед придбанням інвестиційних активів або корпоративних прав).`,
    },
    {
        post : 'Заступник директора',
        name : 'Савченко Віктор Юрійович',
        id : 2,
        about: `Вища економічна освіта, сертифікат аудитора №005762, професійний досвід роботи понад 20 років, їх близько 15 років - на ринку аудиторських послуг. Ключова спеціалізація зараз:
				<br/> - керівництво над проектами щодо проведення обов'язкового аудиту фінансової звітності компаній (в т.ч. та складеної по МСФЗ),
				<br/> - проведення ініціативного аудиту та виконання будь-яких інших процедур, погоджених із клієнтом.`,
    },
]

export default data;