import React, { Component } from 'react'
import { Header } from 'screens'
import Footer from '../components/Footer'
import 'assets/themes/screens/Client.scss'
import { scroll } from './ScrollTotop'

export default class Client extends Component {
	componentDidMount() {
		scroll()
	}

	render() {
		return (
			<>
				<Header />
				<main className={'clients-conteiner'}>
					<div className='clients-interior-wrapper'>
						<div className={'clients-image'}>
							<img
								alt='logo our clients'
								className='clients-conteiner-image'
								src={require('../assets/image/Logo_1@2x.png')}
							/>
							<img
								alt='logo our clients'
								className='clients-conteiner-image'
								src={require('../assets/image/Logo_4@2x.png')}
							/>
							<img
								alt='logo our clients'
								className='clients-conteiner-image'
								src={require('../assets/image/Logo_5@2x.png')}
							/>
							<img
								alt='logo our clients'
								className='clients-conteiner-image'
								src={require('../assets/image/Logo_6@2x.png')}
							/>
						</div>
						<div className={'clients-image'}>
							<img
								alt='logo our clients'
								className='clients-conteiner-image'
								src={require('../assets/image/Logo_8@2x.png')}
							/>
							<img
								alt='logo our clients'
								className='clients-conteiner-image'
								src={require('../assets/image/Logo_2@2x.png')}
							/>
							<img
								alt='logo our clients'
								className='clients-conteiner-image'
								src={require('../assets/image/Logo_3@2x.png')}
							/>
							<img
								alt='logo our clients'
								className='clients-conteiner-image'
								src={require('../assets/image/Logo_9@2x.png')}
							/>
						</div>
					</div>
				</main>
				<Footer />
			</>
		)
	}
}
