import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import 'assets/themes/components/Header.scss'

export default class Header extends Component {
	state = {
		isOpen: false,
	}

	handleOpenMenu = () => {
		this.state.isOpen ? this.setState({ isOpen: false }) : this.setState({ isOpen: true })
	}

	render() {
		return (
			<>
				<header className='header'>
					<div className='header-phone_wrap'>
						<p className='header-phone_text margin'>+380 (97) 091 58 22</p>
					</div>
					<div className='header-container'>
						<NavLink to='/' className='header-logo'></NavLink>
						<div className='header-wrap'>
							<NavLink to='/'  className={`header-wrap_link margin-links`}>
								Про фірму
							</NavLink>
							<NavLink to='/team' activeClassName='border' className={`header-wrap_link margin-links `}>
								Менеджмент
							</NavLink>
							<NavLink to='/service' activeClassName='border' className={`header-wrap_link margin-links`}>
								Послуги
							</NavLink>
				{/*			<NavLink to='/clients' activeClassName='border' className={`header-wrap_link margin-links `}>
								Наши клиенты
		</NavLink> */}
							<NavLink to='/review' activeClassName='border' className={`header-wrap_link `}>
								Партнери
							</NavLink>
							<button className='header-wrap_button margin-btn'>
								<NavLink to='/contact' className='header-wrap_link-btn'>
									Контакти
								</NavLink>
							</button>
						</div>
						<div
							onClick={this.handleOpenMenu}
							className={!this.state.isOpen ? 'header-menu_open' : 'header-menu_close'}
						></div>
					</div>
				</header>
				{this.state.isOpen ? (
					<div className='header-burger'>
						<div className='burger-wrap'>
							<NavLink to='/' onClick={this.handleOpenMenu} className={`header-wrap_link margin-links `}>
								Про фірму
							</NavLink>
							<NavLink to='/team' onClick={this.handleOpenMenu} className={`header-wrap_link margin-links `}>
								Менеджмент
							</NavLink>
							<NavLink to='/service' onClick={this.handleOpenMenu} className={`header-wrap_link margin-links`}>
								Послуги
							</NavLink>
				{/*			<NavLink to='/clients' onClick={this.handleOpenMenu} className={`header-wrap_link margin-links`}>
								Наши клиенты
				</NavLink> */}
							<NavLink to='/review' onClick={this.handleOpenMenu} className={`header-wrap_link margin-links`}>
								Партнери
							</NavLink>
							<NavLink to='/contact' onClick={this.handleOpenMenu} className='header-wrap_link-btn margin-links'>
								Контакти
							</NavLink>
						</div>
					</div>
				) : (
					''
				)}
			</>
		)
	}
}
