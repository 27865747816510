import React, { Component } from 'react'
import { Header } from 'screens'
import ServicesItem from '../components/ServicesItem'
import Footer from '../components/Footer'
import servicesList from '../screens/servicesList'
import { scroll } from './ScrollTotop'

export default class Service extends Component {
	closeItem = (selectedIndex: number): void => {
		servicesList[selectedIndex].selected = !servicesList[selectedIndex].selected

		servicesList.map((serviceItem, serviceItemIndex) => {
			if (selectedIndex !== serviceItemIndex) {
				serviceItem.selected = false
			}
			if (servicesList[selectedIndex].selected) {
				serviceItem.hideFeaturesList = true
			} else {
				serviceItem.hideFeaturesList = false
			}
			return serviceItem
		})
		this.forceUpdate()
	}

	componentDidMount() {
		scroll()
	}

	render() {
		return (
			<>
				<Header />
				<div className={'service-conteiner'}>
					<div className={'service-interior-wrapper'}>
						{servicesList.map((service: any, index) => {
							const close = (index) => this.closeItem(index)
							const border = index === servicesList.length - 1
							return <ServicesItem key={index} {...{ service, index, close, border }} />
						})}
					</div>
				</div>
				<Footer />
			</>
		)
	}
}
