//import UBS from './../assets/image/UBS@2x.png'
import Moriarty from './../assets/image/Moriarty Consulting Sq.png'

export const initialState = {
	reviewList: [
		{

			id: 1,
			img: Moriarty, //Starbucks,
			commentatorName: 'Moriarty Consulting', //'Kevin Johnson',
			commentatorPosition: '', //'CEO at Starbucks',
			commentatorLink: 'https://moriarty.consulting/',
			comment:  ` – це лідируюча міжнародна консалтингова компанія, яка працює з 2012 року і була створена як єдиний консультаційний центр, призначений для підтримки існуючого бізнесу, а також виходу нових гравців на ринок, сприяння їхньому розвитку, вирішенню повсякденних завдань як українських, так і міжнародних компаній. У команді Moriarty Consulting зібрані найкращі уми у сфері бізнесу, юриспруденції, економіки, IT, PR, маркетингу та фінансів.
			В нашій структурі є все необхідне для розвитку бізнесу наших партнерів та пошуку нових можливостей:`,
			commentDetail:[
				'- Організація бізнесу «під ключ»',
				'- Структурування бізнесу',
				'- Маркетинговий консалтинг',
				'- ІТ консалтинг',
				'- Юридичний консалтинг',
				'- Бізнес-розвідка',
				'- Дизайн консалтинг'
			]
		},
	],
	current: 1,
}
