import React, { Component } from 'react'
import { Header } from 'screens'
import Footer from '../components/Footer'
import 'assets/themes/screens/Main.scss'
//import { Link } from 'react-router-dom'
import { scroll } from './ScrollTotop'

export default class Main extends Component {
	componentDidMount() {
		scroll()
	}

	render() {
		return (
			<>
				<Header />
				<main className='main-conteiner'>
					<div className={'main-wrapper'}>
						<div className={'main-content'}>
							<div className={'main-content-history-auditing'}>
								<div className={'history-auditing-text'}>
									<p>
									   ТОВ Аудиторська фірма "АленАудит"
									</p>
									<p>
										була створена в 2007 році і динамічно розвивається на ринку аудиторських та бухгалтерських послуг зараз. За час свого існування ми зуміли підібрати згуртований і кваліфікований штат співробітників, запровадити внутрішньо фірмову систему контролю якості наданих аудиторських послуг і заслужити довіру у значної кількості клієнтів, які прийняли рішення про співпрацю з нами.
									</p>
									<p>
										На підставі рішення АПУ №54/6 від 25.02.2021р, ТОВ Аудиторська фірма «АленАудит» успішно пройшла контроль якості та включена до розділу реєстру «Суб'єкти аудиторської діяльності, які мають право проводити обов'язковий аудит фінансової звітності».
									</p>
								</div>
							</div>
							<div className={'main-content-choice'}>
								<p>Ключові напрямки діяльності:</p>
								<div className={'main-content-choice-wrapper'}>
									<div className={'main-content-choice-text'}>
										<div className={'main-content-choice_blocks'}>
											<p>Бухгалтерський аутсорсинг</p>
										</div>
										<div className={'main-content-choice_blocks'}>
											<p>Консалтингові послуги (консультування з питань бухгалтерського, фінансового, управлінського обліку)</p>
										</div>
										<div className={'main-content-choice_blocks'}>
											<p>Аудит фінансової та податкової звітності</p>
										</div>
										<div className={'main-content-choice_blocks'}>
											<p>Податкове планування та оптимізація</p>
										</div>
										<div className={'main-content-choice_blocks'}>
											<p>Трансферне ціноутворення та новації по контрольованих іноземних компаніях (ТЦО, КІК)</p>
										</div>
										<div className={'main-content-choice_blocks'}>
											<p>Юридичні послуги, податкові та господарські спори</p>
										</div>
										<div className={'main-content-choice_blocks'}>
											<p>Due diligence (оцінка ризиків, пов'язаних з об'єктами інвестування до їхнього придбання)</p>
										</div>
									</div>
								</div>
							</div>
							<div className={'main-content-choice'}>
								<p>Основні групи наших клієнтів:</p>
								<div className={'main-content-choice-wrapper'}>
									<div className={'main-content-choice-text'}>
										<div className={'main-content-choice_blocks'}>
											<p>ІТ-компанії, фрілансери</p>
										</div>
										<div className={'main-content-choice_blocks'}>
											<p>Сільськогосподарські та фермерські товариства</p>
										</div>
										<div className={'main-content-choice_blocks'}>
											<p>Компанії, що здійснюють ЗЕД (імпорт/експорт товарів, робіт, послуг)</p>
										</div>
										<div className={'main-content-choice_blocks'}>
											<p>Промислове підприємство</p>
										</div>
										<div className={'main-content-choice_blocks'}>
											<p>Торгові та будівельні компанії</p>
										</div>
										<div className={'main-content-choice_blocks'}>
											<p>Нерезиденти та їх представництва в Україні</p>
										</div>
									</div>
								</div>
							</div>
							<div className="main-content-doc">
								<p>Договір добровільного страхування</p>
								<p>професійної відповідальності </p>
								<p>суб'єкта аудиторської діяльності</p>
								<img
									className="main-content-doc-img"
									src={require("../assets/image/insurance new1.jpg")}
									alt="insurance certificate">
								</img>
								<img
									className="main-content-doc-img"
									src={require("../assets/image/insurance new2.jpg")}
									alt="insurance certificate">
								</img>
								<img
									className="main-content-doc-img"
									src={require("../assets/image/insurance new3.jpg")}
									alt="insurance certificate">
								</img>
							</div>
						</div>
					</div>
				</main>
				<Footer />
			</>
		)
	}
}
