import React, { Component, Fragment } from 'react'

interface ITeamAboutProps {
	post: string
	name: string
	id: number
	about: string
	img: string
}

export default class TeamAbout extends Component<ITeamAboutProps> {
	state = {
		height: 0,
		animVisible: false,
	}
	componentDidMount() {
		window.addEventListener('scroll', this.handleScrolll)
		this.setState({ height: document.getElementById(`${this.props.id}`).getBoundingClientRect().top })
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScrolll)
	}

	handleScrolll = () => {
		if (window.pageYOffset + 200 >= this.state.height) {
			this.setState({ animVisible: true })
		}
	}

	render() {
		const animVisible = this.state.animVisible
		const { id, post, name, about } = this.props

		return (
			<>
				<Fragment>
					<div id={id.toString()} className={`${id % 2 === 0 ? 'wrap-team--reverse' : 'wrap-team'}`}>
						<div className={`team-wrap--one ${id % 2 === 0 ? 'margin-img' : ''}`}>
							<div className={`team-img${id}`}></div>
							<p className='team-text_post'>{post}</p>
							<p className='team-text_name'>{name}</p>
						</div>
						<div
							className={`${
								id === 1
									? 'team-wrap--two animated  fadeInUp'
									: animVisible
									? 'team-wrap--two animated  fadeInUp'
									: 'team-wrap--two none'
							}`}
							dangerouslySetInnerHTML={{ __html: about }}
						></div>
					</div>
					<hr className='line' />
				</Fragment>
			</>
		)
	}
}
