import React, { Component } from 'react'
import { Header } from 'screens'
import Footer from '../components/Footer'
import Slider from 'react-slick'
import 'assets/themes/screens/Review.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Animated } from 'react-animated-css'
import { initialState } from '../screens/reviewList'
import { scroll } from './ScrollTotop'

function SampleNextArrow(props) {
	const { className, onClick } = props

	return (
		<div className={className} onClick={onClick}>
			<img alt='icon arrow next' className='slider-arrow-next' src={require('../assets/image/Arrow.png')} />
		</div>
	)
}

function SamplePrevArrow(props) {
	const { className, onClick } = props
	return (
		<div className={className} onClick={onClick}>
			<img alt='icon arrow prev' className='slider-arrow-prev' src={require('../assets/image/Arrow.png')} />
		</div>
	)
}

type MyProps = {}
type MyState = typeof initialState

const settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	nextArrow: <SampleNextArrow />,
	prevArrow: <SamplePrevArrow />,
}

export default class Review extends Component<MyProps, MyState> {
	state = initialState

	afterChangeHandler = (slide) => {
		this.setState({ current: slide })
	}

	componentDidMount() {
		scroll()
	}

	render = () => {
		return (
			<>
				<Header />
				<div className={'review-conteiner'}>
					<div className={'review-conteiner-wrapper'}>
						<Slider {...settings} afterChange={this.afterChangeHandler}>
							{this.state.reviewList.map((el, index) => (
								<div key={index} className='review-blocks'>
									<div className='review-commentator-info'>
										<img src={el.img} />

										<p>{el.commentatorPosition}</p>
									</div>
									<div className='review-comment-text'>
										<Animated
											animationIn='bounceInRight'
											isVisible={Number(this.state.current) === Number(el.id)}
											animationOut='fadeOut'
											animationInDelay={100}
										>
											<span>
												<a href={el.commentatorLink}>{el.commentatorName}</a>
											</span>
											<span>{el.comment}</span>
											<br></br>
											<span>{el.commentDetail[0]}</span>
											<br></br>
											<span>{el.commentDetail[1]}</span>
											<br></br>
											<span>{el.commentDetail[2]}</span>
											<br></br>
											<span>{el.commentDetail[3]}</span>
											<br></br>
											<span>{el.commentDetail[4]}</span>
											<br></br>
											<span>{el.commentDetail[5]}</span>
											<br></br>
											<span>{el.commentDetail[6]}</span>
										</Animated>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
				<Footer />
			</>
		)
	}
}
